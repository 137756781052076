document.addEventListener("DOMContentLoaded", function () {
    if (document.getElementsByClassName("swiper-container").length > 0) {
        setTimeout(function () {
            let sliders = document.getElementsByClassName("swiper-container");
            Array.prototype.forEach.call(sliders, function (slider) {
                var options = {};
                options["breakpoints"] = {};
                options["breakpoints"][640] = {};
                options["breakpoints"][900] = {};
                if (slider.dataset.spacebetween) {
                    options["spaceBetween"] = parseInt(slider.dataset.spacebetween);
                }
                let slidesperview = JSON.parse(slider.dataset.slidesperview);
                if (slidesperview) {
                    if (slidesperview.mob) {
                        options["slidesPerView"] = slidesperview.mob;
                    }
                    if (slidesperview.tab) {
                        options["breakpoints"]["640"]["slidesPerView"] =
                            slidesperview.tab;
                    }
                    if (slidesperview.desk) {
                        options["breakpoints"]["900"]["slidesPerView"] =
                            slidesperview.desk;
                    }
                }
    
                if (slider.dataset.arrows) {
                    options["navigation"] = {
                        nextEl: slider.nextElementSibling.nextElementSibling,
                        prevEl: slider.nextElementSibling,
                    };
                }
                if (slider.dataset.dots) {
                    options["pagination"] = {
                        el: ".swiper-pagination",
                        type: "bullets",
                        clickable: true
                    };
                }
                if (slider.dataset.autoplay) {
                    options["autoplay"] = {
                        delay: 3000,
                    };
                }
                
                options["loop"] = true;
    
                if (options) {
                    new Swiper(slider, options);
                }
            });
         }, 300);
	}
});
