// Setup a timer
var timeout;
// setup const
const beforeNav = document.getElementById("nav-main").previousElementSibling;
const nav = document.getElementById("nav-main");
// functions
const moveNavResponsive = () => {
	var Wsize = parseInt(window.innerWidth),
		Nsize = parseInt(nav.dataset.mobile);
	if (Wsize <= Nsize) {
		document.getElementById("outside-content").append(nav);
	} else {
		beforeNav.after(nav);
	}
};
// Listen for resize events
window.addEventListener(
	"resize",
	function (event) {
		// If there's a timer, cancel it
		if (timeout) {
			window.cancelAnimationFrame(timeout);
		}

		// Setup the new requestAnimationFrame()
		timeout = window.requestAnimationFrame(function () {
            moveNavResponsive();
		});
	},
	false
);


window.addEventListener('load', moveNavResponsive())